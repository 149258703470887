<template>
    <div class="row justify-content-center mt-5">
      <div :class="{'footer-login-home':type === 'home', 'footer-login':type !== 'home'}" v-if="!isMobile">
        <p>{{ $t('base.footer_base') }}</p>
        <a href="https://greenn.com.br/" target="_blank" rel="noopener noreferrer">
          <img v-if="themeDark" src="@/assets/greenn_club.png" alt="logogreenn">
          <img v-if="!themeDark" src="@/assets/greenn__club_0.png" alt="logogreenn">
        </a>
      </div>
      <div :class="{'footer-login-home':type === 'home', 'footer-login-mobile':type !== 'home'}" v-if="isMobile">
        <p>{{ $t('base.footer_base') }}</p>
        <a href="https://greenn.com.br/" target="_blank" rel="noopener noreferrer">
          <img v-if="themeDark" src="@/assets/greenn_club.png" alt="logogreenn">
          <img v-if="!themeDark" src="@/assets/greenn__club_0.png" alt="logogreenn">
        </a>
      </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    props:['type'],
    data() {
    return {
      client:{
        width: 0
      },
      themeDark:false
    };
  },
    computed: {
        isMobile() {
        return this.client.width <= 576;
        },
    },
    created(){
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    mounted(){
      this.getTheme();
      this.$root.$on("loadsettheme", () => {
        this.getTheme();
      });
    },
    methods: {
      getTheme(){
        const d = document;
        var theme = Cookies.get("themeClub");
        if (theme === undefined || theme === null) {
          this.themeDark = false
        } else {
          d.documentElement.getAttribute("data-theme");
          if (theme === "dark") {
            this.themeDark = true
          } else {
            this.themeDark = false
          }
        }
      },
      handleResize() {
          this.client.width = window.innerWidth;
      }, 
    }
}
</script>

<style lang="scss" scoped>
.footer-login {
  position: absolute;
  bottom: 10px;
  display: flex;
}
.footer-login-home {
  position: absolute;
  display: flex;
}
.footer-login p,  .footer-login-home p{
  font-size: 10px;
  font-family: Montserrat;
  margin-right: 3px;
  color:var(--fontcolor);
  margin-bottom: 10px;
}
.footer-login span, .footer-login-home span {
  font-weight: 600;
  color: var(--maincolor);
}

.footer-login img, .footer-login-home img {
  width: 100px;
  margin-top:-1px;
}

.footer-login-mobile {
  position: absolute;
  bottom: 10px;
  display: flex;
}

.footer-login-mobile img {
  width: 100px;
  margin-top:-1px;
}

.footer-login-mobile p {
  font-size: 10px;
  font-family: Montserrat;
  margin-right: 15px;
}
.footer-login-mobile span {
  font-weight: 600;
  color: var(--maincolor);
}
</style>
